import React from 'react'
import { graphql } from 'gatsby'
import { Flex } from 'baselift'
import { useTheme } from 'emotion-theming'
import { Content } from '../components/content'
import { useBreakpoint } from '../hooks/use-breakpoint'
import { processPageHtml } from '../helpers/html-helpers'
import { Layout } from '../layouts'
import { Seo } from '../components/seo'
import { pageTemplateStyle } from '../styles/page-template-style'

const PageTemplate = ({ data }) => {
  const {
    title,
    body: {
      childMarkdownRemark: { html },
    },
  } = data.contentfulPage

  const theme = useTheme()
  const breakpointify = useBreakpoint(theme.breakpoints)
  const finalHtml = processPageHtml(html)

  return (
    <Layout>
      <Seo title={title} description="A versatile camera plugin for Unity" />
      <Flex
        css={breakpointify({
          marginLeft: [0, 0, 0, theme.unit(5), 0],
          transition: 'margin-left 0.25s cubic-bezier(0.5, 0, 0.25, 1)',
        })}
      >
        <Content css={breakpointify(pageTemplateStyle)} dangerouslySetInnerHTML={{ __html: finalHtml }} />
      </Flex>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      slug
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
